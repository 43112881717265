import { social } from '@/component/editor/tiptap/plugin/group/social';
import Tiptap_article from '@/component/editor/tiptap/tiptap_article/tiptap_article';
import { I_Tiptap_common } from '@/component/editor/tiptap/tiptap_common/tiptap_common';
import React, { FC, memo } from 'react';

export interface I_Tiptap_article_social extends I_Tiptap_common {}

const Tiptap_article_social: FC<I_Tiptap_article_social> = memo<I_Tiptap_article_social>(
  (props: I_Tiptap_article_social) => {
    const { extensions, ...rest } = props;

    return (
      <div>
        <div>
          <Tiptap_article {...rest} extensions={[...social, ...(extensions ?? [])] as any} />
        </div>
      </div>
    );
  },
);

export default Tiptap_article_social;
