import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import { Editor, EditorContent } from '@tiptap/react';
import { EditorView } from 'prosemirror-view';
import React, { ComponentProps, FC, memo, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';

export interface I_Tiptap_base extends ComponentProps<typeof EditorContent> {
  /**
   * increment to clear the editor
   */
  clear?: number;

  focus?: number;

  editor: Editor | null;

  compact?: boolean;
}

const Tiptap_base: FC<I_Tiptap_base> = memo<I_Tiptap_base>((props: I_Tiptap_base) => {
  const { editor, content, clear, focus, compact, className, ...rest } = props;
  const clear_prev = usePrevious(clear);

  useEffect(() => {
    if (clear && clear !== clear_prev && editor) {
      editor.commands.setContent('');
    }
  }, [editor, clear, clear_prev]);

  useEffect(() => {
    editor?.setOptions({
      editorProps: {
        attributes: {
          class: 'rich_editor',
        },
      },
    });
  }, [editor]);

  useEffect(() => {
    const el = editor?.view.dom;
    el?.dataset && (el.dataset.testid = 'input');
    focus && editor?.commands.focus();
  }, [editor, focus]);

  return editor ? (
    <EditorContent
      {...rest}
      editor={editor}
      className={twMerge(`rich_text break-all ${compact ? 'compact' : ''}`, className)}
    />
  ) : (
    <Icon_loading />
  );
});

export default Tiptap_base;

// hack: https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  if (!(this as any).docView) return; // This prevents the matchesNode error on hot reloads
  (this as any).updateStateInner(state, this.state.plugins != state.plugins);
};
