import Code_block_highlight from '@/component/editor/tiptap/plugin/code_block/code_block';
import { data } from '@/component/editor/tiptap/plugin/group/data';
import { list } from '@/component/editor/tiptap/plugin/group/list';
import { mark_text } from '@/component/editor/tiptap/plugin/group/mark_text';
import { multiline } from '@/component/editor/tiptap/plugin/group/multiline';
import { Rich_image } from '@/component/editor/tiptap/plugin/rich_image/rich_image';
import Blockquote from '@tiptap/extension-blockquote';
import Heading from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import { Extensions } from '@tiptap/react';

export const article: Extensions = [
  ...mark_text,
  ...multiline,
  ...list,
  ...data,
  Subscript,
  Superscript,
  Heading,
  Blockquote,
  Code_block_highlight,
  Rich_image,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https',
  }),
];
