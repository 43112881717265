import { base } from '@/component/editor/tiptap/plugin/group/base';

import Tiptap_base, { I_Tiptap_base } from '@/component/editor/tiptap/tiptap_base/tiptap_base';
import { I_Upload_area } from '@/component/uploader/upload_area/upload_area';
import { File_entity } from '@/component/uploader/uploader_general/uploader_general';
import { File } from '@/declaration/rds/model';
import { loading } from '@/utility/dynamic/loading';
import Placeholder from '@tiptap/extension-placeholder';
import { Editor, EditorEvents, EditorOptions, Extensions, useEditor } from '@tiptap/react';
import dynamic from 'next/dynamic';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useCounter, usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';

const Upload_area = dynamic(() => import('@/component/uploader/upload_area/upload_area'), {
  loading,
});

export interface I_Tiptap_common extends Omit<I_Tiptap_base, 'editor'> {
  on_render?: (editor: Editor | null) => void;

  on_update?: EditorOptions['onUpdate'];

  on_change?: (data: { html: string; text: string }) => void;

  on_transaction?: EditorOptions['onTransaction'];

  on_raw_change?: (html: string) => void;

  on_text_change?: (text: string) => void;

  on_editor_load?: (editor: Editor | null) => void;

  readonly?: boolean;

  placeholder?: string;

  i_editor?: Partial<EditorOptions>;

  extensions?: Extensions;

  enable_upload?: boolean;

  i_upload?: I_Upload_area;

  /**
   * HTML content
   */
  raw?: string;
}

const Tiptap_common: FC<I_Tiptap_common> = memo<I_Tiptap_common>((props: I_Tiptap_common) => {
  const {
    on_render,
    on_update,
    on_change,
    on_transaction,
    on_raw_change,
    on_text_change,
    on_editor_load,
    i_editor,
    extensions,
    readonly,
    placeholder,
    className,
    enable_upload,
    raw,
    i_upload,
    ...rest
  } = props;
  if (enable_upload) {
    if (!i_upload?.purpose || !i_upload?.target || !i_upload?.tid) {
      throw new Error('Upload: purpose, target, tid are required');
    }
  }
  const [raw_change_count, { inc: raw_change_count_inc }] = useCounter(0);
  // const [pos, set_pos] = useState<any>();
  // const [waits, set_waits] = useState([]);
  const raw_prev = usePrevious(raw);

  const _on_transaction = useCallback<EditorOptions['onTransaction']>(
    (data) => {
      // const {
      //   editor: { state },
      // } = data;
      // set_pos(state.selection.$anchor.pos);
      on_transaction?.(data);
    },
    [on_transaction],
  );

  const _on_update = useCallback(
    (data: EditorEvents['update']) => {
      const { editor } = data;
      const html = editor.getHTML();
      const text = editor.state.doc.textContent;
      on_update?.(data);
      on_change?.({ html, text });
      if (raw_change_count) {
        on_raw_change?.(html);
        on_text_change?.(text);
      }
    },
    [on_change, on_raw_change, on_text_change, on_update, raw_change_count],
  );

  const _on_create = useCallback(
    (data: EditorEvents['update']) => {
      on_editor_load && on_editor_load(data?.editor as any);
      _on_update(data);
    },
    [_on_update, on_editor_load],
  );

  const editor = useEditor({
    ...((i_editor ?? {}) as any),
    onUpdate: _on_update,
    onCreate: _on_create,
    readOnly: readonly,
    onTransaction: _on_transaction,
    attributes: {
      class: 'rich_editor',
    },
    extensions: [
      ...base,
      Placeholder.configure({
        placeholder,
        emptyEditorClass: 'editor_empty',
      }),
      ...(i_editor?.extensions ?? []),
      ...(extensions ?? []),
    ],
  });

  const editor_prev = usePrevious(editor);

  const on_upload = useCallback(
    (_fe: File_entity, file?: File) => {
      const src = file?.url?.source;
      if (!src) {
        throw new Error('File url is empty');
      }
      editor
        ?.chain()
        .focus()
        .set_image({
          src,
          alt: file?.name_org,
          title: file?.name_org,
          'data-id': file?.id,
          'data-label': file?.unique,
          'data-filename': file?.unique, // todo: missing ext
          'data-mime': file?.mime,
          'data-type': 'image',
        } as any)
        .run();
      editor?.commands.createParagraphNear();
    },
    [editor],
  );

  const inner = useMemo(
    () => (
      <Tiptap_base
        {...rest}
        editor={editor}
        className={twMerge('max-h-[calc(100vh-100px)] overflow-auto', className)}
      />
    ),
    [className, editor, rest],
  );

  const update_content = useCallback(
    (raw?: string) => {
      editor?.commands.setContent(raw ?? '');
      raw_change_count_inc();
    },
    [editor?.commands, raw_change_count_inc],
  );

  useEffect(() => {
    on_render && on_render(editor);
  });

  useEffect(() => {
    if (editor) {
      if (editor_prev) {
        if (raw_prev !== raw) {
          update_content(raw);
        }
      } else {
        update_content(raw);
      }
    }
  }, [editor, editor_prev, raw, raw_prev, update_content]);

  return enable_upload ? (
    <Upload_area on_upload={on_upload} {...(i_upload as I_Upload_area)}>
      {inner}
    </Upload_area>
  ) : (
    inner
  );
});

export default Tiptap_common;
