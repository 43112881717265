import { Smart_link } from '@/component/editor/tiptap/plugin/smart_link/smart_link';

import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import History from '@tiptap/extension-history';
import Text from '@tiptap/extension-text';
import Typography from '@tiptap/extension-typography';
import { Extensions } from '@tiptap/react';

export const base: Extensions = [
  Document,
  Dropcursor,
  Gapcursor,
  History,
  Text,
  Typography,
  Smart_link.configure({
    protocols: ['mailto'],
  }),
];
