import Btn from '@/component/button/btn/btn';
import Tiptap_article_social from '@/component/editor/tiptap/tiptap_article_social/tiptap_article_social';
import { Feedback } from '@/declaration/rds/model/feedback';
import { N_file_purpose } from '@/declaration/rds/model/type/n_file_purpose';
import { N_file_target } from '@/declaration/rds/model/type/n_file_target';
import { N_stage_feedback } from '@/declaration/rds/model/type/n_stage_feedback';
import { N_type_feedback } from '@/declaration/rds/model/type/n_type_feedback';
import { useApi } from '@/hook/api/useApi';
import { useTap } from '@/hook/state/useTap';
import { radio } from '@/state/radio';
import { N_rich_format } from '@mosteast/rich_kit';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLProps, memo, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useCounter, usePrevious, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { Updater, useImmer } from 'use-immer';

export interface I_Form_feedback extends HTMLProps<HTMLFormElement> {
  row?: Partial<Feedback>;
  on_submit?: (row: Feedback) => void;
  /**
   * Restrict the type of feedback
   */
  type?: N_type_feedback;

  form_prepend?: ({ form, set_form }: { form: Partial<Feedback>; set_form: Updater<Partial<Feedback>> }) => ReactNode;
  form_append?: ({ form, set_form }: { form: Partial<Feedback>; set_form: Updater<Partial<Feedback>> }) => ReactNode;
}

const Form_feedback: FC<I_Form_feedback> = memo<I_Form_feedback>(
  ({ className, row, on_submit, type, form_append, form_prepend, ...rest }: I_Form_feedback) => {
    const { t } = useTranslation();
    const def = useMemo<Partial<Feedback>>(
      () => ({ type: type ? type : N_type_feedback.bug_report, ...row }),
      [row, type],
    );
    const [form, set_form] = useImmer<Partial<Feedback>>(def);
    const form_prev = usePrevious(form);
    const [draft, set_saved] = useImmer<Feedback | undefined>(undefined);
    const _types = useMemo(
      () =>
        type
          ? [type]
          : [
              N_type_feedback.bug_report,
              N_type_feedback.feature_request,
              N_type_feedback.report,
              N_type_feedback.regular,
            ],
      [type],
    );

    const { call: api_save, pending: p_save } = useApi('feedback/save');
    const { call: api_draft_ensure, pending: p_draft_ensure } = useApi('feedback/draft_ensure');
    const disabled = useMemo(() => p_save || !form?.content?.raw?.trim(), [form?.content?.raw, p_save]);
    const _prop = useCallback((key: string) => t(`model.feedback:property.${key}.name`), [t]);
    const [clear_content, { inc: clear_content_inc }] = useCounter(0);
    const dirty = useMemo(
      () =>
        form.type !== form_prev?.type ||
        form.title !== form_prev?.title ||
        form.content?.raw !== form_prev?.content?.raw,
      [form.content?.raw, form.title, form.type, form_prev?.content?.raw, form_prev?.title, form_prev?.type],
    );
    const [loaded, toggle_loaded] = useToggle(false);
    const i_upload = useMemo(
      () => ({
        purpose: N_file_purpose.feedback_image,
        target: N_file_target.feedback,
        tid: form.id as any,
      }),
      [form.id],
    );
    const [draft_load_error, draft_load_error_toggle] = useToggle(false);

    const load_draft = useCallback(async () => {
      if (p_draft_ensure) {
        return;
      }
      try {
        const r = await api_draft_ensure({ type: form.type, ...def });
        toggle_loaded(true);
        if (!r) {
          return;
        }
        set_saved(r);
        set_form({ ...r, ...def });
      } catch (e: any) {
        draft_load_error_toggle(true);
      }
    }, [api_draft_ensure, def, draft_load_error_toggle, form.type, p_draft_ensure, set_form, set_saved, toggle_loaded]);

    const save = useCallback(
      async (patch?: Partial<Feedback>) => {
        if (!loaded || (!form.title && !form.content?.raw)) {
          return;
        }
        const r = await api_save({ ...form, ...patch });
        if (!form.id) {
          set_form(r);
        }
        return r;
      },
      [loaded, api_save, form, set_form],
    );

    const publish = useCallback(
      async (e?: any) => {
        e?.preventDefault();
        const r = await save({ stage: N_stage_feedback.created });
        on_submit?.(r);
        set_form({ ...def });
        clear_content_inc();
        radio.popup.next({ title: t('common:done'), type: 'success' });
        await load_draft();
      },
      [clear_content_inc, def, load_draft, on_submit, save, set_form, t],
    );

    // const on_change_title = useCallback(
    //   (e: any) => {
    //     set_form((draft) => {
    //       draft.title = e.target.value;
    //     });
    //   },
    //   [set_form],
    // );

    const on_change_content = useCallback(
      ({ html, text }: any) => {
        set_form((s: any) => {
          s.content = s.content || {};
          s.content.raw = text.trim() ? html : '';
          s.content.format = N_rich_format.html;
        });
      },
      [set_form],
    );

    // useEffect(() => {
    //   if (loaded) {
    //     lset([N_lkey.feedback], form);
    //   } else {
    //     setTimeout(() => {
    //       if (!form.title && !form.content?.raw) {
    //         const data = lget([N_lkey.feedback]);
    //         set_form(data || {});
    //       }
    //       set_loaded(true);
    //     }, 50);
    //   }
    // }, [form, loaded, set_form]);

    const { tap } = useTap(save);

    useEffect(() => {
      if (dirty) {
        tap();
      }
    }, [dirty, tap]);

    useEffect(() => {
      if (!draft && !loaded && !draft_load_error) {
        void load_draft();
      }
    }, [loaded, load_draft, draft, draft_load_error]);

    return (
      <form {...rest} onSubmit={publish} className={twMerge('c gap-2', className)} data-testid="Form_feedback">
        <div className="r gap-2 items-center">
          <div className="title cap">{t('common:feedback')}</div>
          {!dirty && <div className="help">{t('form:saved')}</div>}
        </div>
        {form_append?.({ form, set_form })}
        <div>
          <FormControl>
            <FormLabel>{t(`model.feedback:property.type.name`)}</FormLabel>
            <RadioGroup
              value={form?.type}
              onChange={(e) => {
                set_form((s) => {
                  s.type = e.target.value as any;
                });
              }}
            >
              {_types.map((it) => (
                <FormControlLabel
                  key={it}
                  value={it}
                  control={<Radio />}
                  label={t(`model.feedback:enum.type.option.${it}.name`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div>{t('feedback:desc')}</div>
        <div className="border rounded px-2">
          <Tiptap_article_social
            clear={clear_content}
            raw={draft?.content?.raw}
            on_change={on_change_content}
            placeholder={_prop('content')}
            enable_upload={!!form.id}
            i_upload={i_upload}
          />
        </div>
        {form_append?.({ form, set_form })}
        <div className="r gap-2 items-center">
          <Btn disabled={disabled} type="submit">
            {t('form:submit')}
          </Btn>
        </div>
      </form>
    );
  },
);

export default Form_feedback;
