export enum N_file_purpose {
  system = 'system',
  user_regular = 'user_regular',
  user_avatar = 'user_avatar',
  comment_media = 'comment_media',
  legal_entity_shot_back = 'legal_entity_shot_back',
  legal_entity_shot_front = 'legal_entity_shot_front',
  message_attachment = 'message_attachment',
  feedback_attachment = 'feedback_attachment',
  feedback_image = 'feedback_image',
  re_image = 're_image',
  re_video = 're_video',
  re_attachment = 're_attachment',
  re_field_attachment = 're_field_attachment',
}
