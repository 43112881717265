import { Mention, MentionOptions } from '@tiptap/extension-mention';
import { Node } from '@tiptap/react';
import merge from 'lodash/merge';

export const User_mention = Mention.extend({
  parseHTML(): any {
    return [
      {
        tag: 'a',
        getAttrs: (element: any) => element.getAttribute('data-type') === this.name,
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'a',
      merge({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderText({
        options: this.options,
        node,
      }),
    ];
  },
}) as Node<MentionOptions, any>;
