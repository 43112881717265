import Action_bar_article from '@/component/editor/tiptap/action/action_bar/action_bar_article/action_bar_article';
import { article } from '@/component/editor/tiptap/plugin/group/article';
import Tiptap_common, { I_Tiptap_common } from '@/component/editor/tiptap/tiptap_common/tiptap_common';
import { Transaction } from '@tiptap/pm/state';
import { Editor, EditorEvents } from '@tiptap/react';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useCounter } from 'react-use';

export interface I_Tiptap_article extends I_Tiptap_common {}

const Tiptap_article: FC<I_Tiptap_article> = memo<I_Tiptap_article>((props: I_Tiptap_article) => {
  const {} = props;
  const [editor, set_editor] = useState<Editor | null>(null);
  const [tx, set_tx] = useState<Transaction>();
  const extensions: any = useMemo(() => [...article, ...(props.extensions ?? [])], [props.extensions]);
  const [count_update, { inc: count_update_inc }] = useCounter(0);

  const on_update = useCallback(
    ({ editor, transaction }: EditorEvents['update']) => {
      set_editor(editor as any);
      set_tx(transaction);
      count_update_inc();
    },
    [count_update_inc],
  );

  return (
    <div>
      <div>
        <Tiptap_common on_update={on_update} {...props} extensions={extensions} className="max-h-[calc(100vh-200px)]" />
      </div>
      {editor?.isEditable && <Action_bar_article key={count_update} editor={editor as any} />}
    </div>
  );
});

export default Tiptap_article;
