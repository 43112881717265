import { useCallback, useEffect, useState } from 'react';
import { useDebounce, usePrevious } from 'react-use';

export function useTap(on_debounce: (...args: any) => any, debounce?: number): { tap: () => void } {
  debounce = debounce || 300;
  const [dirty, set_dirty] = useState<number>(0);
  const [de_dirty, set_de_dirty] = useState<number>();
  const de_dirty_prev = usePrevious(de_dirty);
  useDebounce(() => set_de_dirty(dirty), debounce, [dirty]);

  const tap = useCallback(() => {
    set_dirty(dirty + 1);
  }, [dirty]);

  useEffect(() => {
    if (de_dirty !== de_dirty_prev) {
      void on_debounce();
    }
  }, [de_dirty, de_dirty_prev, on_debounce]);

  return { tap };
}
