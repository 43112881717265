import Hashtag from '@/component/editor/tiptap/plugin/hashtag/hashtag';
import suggestion_hashtag from '@/component/editor/tiptap/plugin/hashtag/suggestion';
import suggestion_user from '@/component/editor/tiptap/plugin/user_mention/suggestion';
import { User_mention } from '@/component/editor/tiptap/plugin/user_mention/user_mention';
import { Extensions } from '@tiptap/react';

export const social: Extensions = [
  // todo: currently not working for chinese characters
  Hashtag.configure({
    suggestion: suggestion_hashtag,
  }),
  User_mention.configure({
    HTMLAttributes: {
      class: 'rich_mention',
    },
    suggestion: suggestion_user,
  }),
];
