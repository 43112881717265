import Btn_action from '@/component/button/btn_action/btn_action';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import React, { FC, HTMLAttributes, memo, useCallback } from 'react';

export interface I_Action_bar extends HTMLAttributes<HTMLDivElement> {
  editor: Editor;
}

const class_active = 'btn_active';

const Action_bar_article: FC<I_Action_bar> = memo<I_Action_bar>((props: I_Action_bar) => {
  const { editor } = props;
  if (!editor) {
    throw new Error('editor is not defined');
  }

  const { nodes, marks } = editor.schema;

  const heading_toggle = useCallback(
    (level: Level) => {
      editor.chain().focus().toggleHeading({ level }).run();
    },
    [editor],
  );

  const link_toggle = useCallback(() => {
    const url_prev = editor.getAttributes('link').href;

    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run();
      return;
    }

    const url = window.prompt('URL', url_prev);

    // cancelled
    if (url === null || url === url_prev) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);
  return (
    <div {...props}>
      <fieldset className="flex flex-row flex-wrap gap-[2px]">
        {marks.bold && (
          <Btn_action
            className={`${editor.isActive('bold') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <FormatBoldIcon />
          </Btn_action>
        )}
        {marks.italic && (
          <Btn_action
            className={`${editor.isActive('italic') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <FormatItalicIcon />
          </Btn_action>
        )}
        {marks.strike && (
          <Btn_action
            className={`${editor.isActive('strike') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            <FormatStrikethroughIcon />
          </Btn_action>
        )}

        {nodes.heading && (
          <>
            <Btn_action
              className={`${editor.isActive('heading', { level: 1 }) ? class_active : ''}`}
              onClick={() => heading_toggle(1)}
            >
              <LooksOneIcon />
            </Btn_action>
            <Btn_action
              className={`${editor.isActive('heading', { level: 2 }) ? class_active : ''}`}
              onClick={() => heading_toggle(2)}
            >
              <LooksTwoIcon />
            </Btn_action>
            <Btn_action
              className={`${editor.isActive('heading', { level: 3 }) ? class_active : ''}`}
              onClick={() => heading_toggle(3)}
            >
              <Looks3Icon />
            </Btn_action>
          </>
        )}

        {nodes.blockquote && (
          <Btn_action
            className={`${editor.isActive('blockquote') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
          >
            <FormatQuoteIcon />
          </Btn_action>
        )}

        {nodes.bulletList && (
          <Btn_action
            className={`${editor.isActive('bulletList') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <FormatListBulletedIcon />
          </Btn_action>
        )}

        {nodes.orderedList && (
          <Btn_action
            className={`${editor.isActive('orderedList') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <FormatListNumberedIcon />
          </Btn_action>
        )}

        {nodes.codeBlock && (
          <Btn_action
            className={`${editor.isActive('codeBlock') ? class_active : ''}`}
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          >
            <DataObjectIcon />
          </Btn_action>
        )}

        <Btn_action className={`${editor.isActive('link') ? class_active : ''}`} onClick={link_toggle}>
          <InsertLinkIcon />
        </Btn_action>

        <Btn_action
          className={`${editor.isActive('superscript') ? class_active : ''}`}
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
        >
          <SuperscriptIcon />
        </Btn_action>
        <Btn_action
          className={`${editor.isActive('subscript') ? class_active : ''}`}
          onClick={() => editor.chain().focus().toggleSubscript().run()}
        >
          <SubscriptIcon />
        </Btn_action>
      </fieldset>
    </div>
  );
});

export default Action_bar_article;
