import { I_user_search } from '@/declaration/rds/model/type/i_user_search';
import { $api } from '@/service/$api';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import tippy from 'tippy.js';

import List from './list';

let timer: any;

const suggestion: Omit<SuggestionOptions, 'editor'> = {
  items: async ({ query }) => {
    return [{ id: 1, name: 'please_configure_hashtag_suggestion' }];
    timer && clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(async () => {
        const r = await $api.call('tag/search', { keyword: query, page: { size: 6 } } as I_user_search);
        resolve(r.list);
      }, 500);
    });
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(List, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: any): any {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component?.ref?.onKeyDown(props);
      },

      onExit() {
        popup?.[0]?.destroy();
        component?.destroy();
      },
    };
  },
};

export default suggestion;
